import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-instructions-dialog',
  templateUrl: './instructions-dialog.component.html',
  styleUrls: ['./instructions-dialog.component.scss']
})
export class InstructionsDialogComponent implements OnInit {
  instructionsList:any = [];
  constructor(public dialog: MatDialogRef<InstructionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    /*this.instructionsList = [
      'Start the test when you are all set.',
      'Remember there are no right or wrong answers.',
      'Kindly be as honest as possible and do not guess or look for a response that may seem right as there is no right or wrong response',
      'Do not take too much time or ponder over an item, answer as quickly as you can',
      'Time: Just about 15 Minutes. Yaay'
    ]*/
    if(this.data && this.data.instructionsList) {
      this.instructionsList = this.data.instructionsList;
    }
    
  }
  
}
