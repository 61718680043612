<div id="inst-popup" class="container-fluid" style="height: 100%;">
    <div class="row" style="height: 50px;">
      <div class="col-sm-12 pl-0 pr-0">
        <div class="float-left instructions-header">
          Instructions
        </div>
        <div class="float-right">
          <mat-icon class="close-dialog" mat-dialog-close>close</mat-icon>
        </div>
        <hr class="hr-space" />
      </div>
    </div>
    <div class="row" id="instPopup" style="height: calc(100% - 50px);overflow-y: auto;">
      <div class="col-sm-12" *ngFor="let item of instructionsList">
        <div class="d-flex">
          <div class="pr-2">
            <img src="../../../assets/svg/assessments/instructions.svg" class="instruction-icon">
            <!-- <mat-icon svgIcon="sub-menu" class="instruction-icon mr-2"></mat-icon> -->
          </div>
          <div class="instructions-list">
            {{item.title}}
          </div>
        </div>
      </div>
    </div>
