import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogServiceService {
  public dialogStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dialogStatus$: Observable<boolean> = this.dialogStatusSubject.asObservable();
  public broadcastChannel: BroadcastChannel;
  constructor(private dialog: MatDialog){
    this.broadcastChannel = new BroadcastChannel('my-app-channel');
  }  
  sendMessage(message: string): void {
    this.broadcastChannel.postMessage(message);
  }

  onMessageReceived(callback: (message: MessageEvent) => void): void {
    this.broadcastChannel.onmessage = callback;
  }
}
