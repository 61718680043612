import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeadsquaredService {
  apiUrl:string;
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': '',    })
  };


  salesGroupId:string = 'ed2fba65-8713-11eb-8ec5-0ace817c465a'
  constructor(private http:HttpClient
  ) {
    this.apiUrl = environment.ApiConfig.leadsquared;

  }

  createNewLead(userData) {
    console.log('createNewLead---------', userData)
    return this.http.post(`${this.apiUrl}/leadsquare/leads`,userData)
  }

  updateLead(userDatad) {
    console.log('updateLead', userDatad)
    return this.http.post(`${this.apiUrl}/leadsquare/leads/update`,userDatad)
  }

  checkLeadExists(email) {
    console.log('email---checkLeadExists', email)
    return this.http.get(`${this.apiUrl}/leadsquare/leads?emailaddress=${email}`)
  }

  updateActivity(data) {
    console.log('updateActivity------', data)
    return this.http.post(`${this.apiUrl}/leadsquare/activity`,data)
  }
  
  checkLeadByPhone(phone) {
    console.log('phone---checkLeadByPhone', phone)
    return this.http.get(`${this.apiUrl}/leadsquare/leads?phone=${phone}`)
  }

  getSalesUsers() {
    return this.http.get(`${this.apiUrl}/leadsquare/sales-group/users?groupId=${this.salesGroupId}`)
  }

  getAssigneeByState(entity_id,val) {
    console.log('state,entity_id,val', entity_id,val)
    return this.http.get(`${this.apiUrl}/leadsquare/assignee?entity_id=${entity_id}&ownerType=${val}`);
  }
}
