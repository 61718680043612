import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private http:HttpClient) { }

  getIpAddress() {
    //return this.http.get('https://api.ipgeolocation.io/ipgeo?apiKey=a844af63e16f4bb39adf6c762215a100')
    return this.http.get('https://ipwhois.app/json/')
  }
}
